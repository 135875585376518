import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'gatsby';


class Address extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="OpeningHours">
            <div className={classes.horizontalLine} />
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.centerContainer}>
                  <h3>Still need help?</h3>
                  <div className={classes.textCenter}>
                    <Link to="/contact-us" className={classes.listItem}>
                      <Button color="info" size="lg">
                      Contact us today
                      </Button>
                    </Link>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.space50} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(Address);
