import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import introStyle from 'assets/jss/material-kit-react/views/homePageSections/introStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

class Info extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <GridContainer spacing={16}>
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <h3>
                  The chassis is the backbone of Land Rovers and Range Rovers;
                  between 1948-1998 all models used a separate box section
                  chassis (two c sections welded together on later models) The
                  Freelander was the first vehicle to use a Monocoque design in
                  1998 along with the third generation L322 Range Rover released
                  in 2002, right up until 2016 Discovery 4 models and Defenders
                  still used a separate chassis.
                </h3>
              </div>
            </GridItem>
          </GridContainer>

          <div id="Intro">
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <h5>
                    We can offer comprehensive repair of all chassis such as
                    replacing cross-members and out-riggers but there does come
                    a point where it is just not economically viable; your best
                    option then is to have us fit a new and improved over
                    factory chassis. Richards Chassis offer chassis to suit most
                    Land Rover vehicles using 3mm steel for the main sections,
                    they are box welded rather then two c sections welded
                    together so much stronger and hot dipped galvanised for
                    longevity.
                    <br />
                    <br />
                    LR-XS offer various solutions when it comes to a chassis
                    swap on your Land Rover or Range Rover so we suggest booking
                    the vehicle in for a pre-assessment as no two Land Rovers
                    are the same.
                    <br />
                    <br />
                    There is not a one size fits all option and we are not a fan
                    of simply throwing the old body and running gear straight on
                    to a new chassis without at least some regard to checking
                    and renewing key components, I have seen customers new to
                    ourselves running a new chassis but with poor fitment and
                    the vehicles have subsequently failed the MOT inspection.
                    <br />
                    <br />
                    This does not mean that we fully rebuild every vehicle that
                    requires a new chassis, but we take care and attention to
                    ensure that the customer is always receiving value for money
                    and we explain any options clearly in writing before any
                    work is undertaken.
                    <br />
                    <br />
                    Our customers tell us that they like our honest and open
                    approach to any task we are charged with, this ensures
                    transparency and trust, this is the foundation from which we
                    work on.
                    <br />
                    <br />
                    Contact us today to talk about our Land Rover galvanised
                    chassis replacement service.
                  </h5>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(introStyle)(Info);
