import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import galleryStyle from 'assets/jss/material-kit-react/views/homePageSections/galleryStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

function TripleGallery({ ...props }) {
  const {
    classes, imageOne, imageTwo, imageThree,
  } = props;
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div id="Gallery">
          <GridContainer spacing={16}>
            <GridItem xs={12} sm={12} md={4}>
              <img
                src={require(`assets/img/${imageOne}.jpg`)}
                alt="..."
                className={
                  `${classes.imgRaised
                  } ${
                    classes.imgRounded
                  } ${
                    classes.imgFluid}`
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} className={classes.marginLeft}>
              <img
                src={require(`assets/img/${imageTwo}.jpg`)}
                alt="..."
                className={
                  `${classes.imgRaised
                  } ${
                    classes.imgRounded
                  } ${
                    classes.imgFluid}`
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} className={classes.marginLeft}>
              <img
                src={require(`assets/img/${imageThree}.jpg`)}
                alt="..."
                className={
                  `${classes.imgRaised
                  } ${
                    classes.imgRounded
                  } ${
                    classes.imgFluid}`
                }
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(galleryStyle)(TripleGallery);
